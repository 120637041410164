/* eslint-disable  */

export default {
  "About us": "Over ons",
  Account: "Account",
  "Add new address": "Voeg nieuw adres toe",
  "Add to compare": "Vergelijken",
  "Add to Wishlist": "Toevoegen aan verlanglijst",
  "Additional Information": "Aanvullende informatie",
  "All Orders": "Alle bestellingen",
  "Allow order notifications": "Bestelmeldingen toestaan",
  Apply: "Toepassen",
  "Applied Coupon": "Coupon toegepast",
  "Attention!": "Let op!",
  "Back to home": "Terug naar home",
  "Back to homepage": "Terug naar homepagina",
  Billing: "Factuur",
  "Billing address": "Factuur adres",
  Brand: "Merk",
  Cancel: "Annuleren",
  Cart: "Winkelwagen",
  Categories: "Catogorieën",
  Change: "Wijzig",
  "Change password your account": "Als u het wachtwoord voor toegang tot uw account wilt wijzigen, voer de volgende informatie in",
  "Clear all": "Alles verwijderen",
  Color: "Kleur",
  "Commercial information": "en ga ermee akkoord om gepersonaliseerde informatie van SHOTS per e-mail te ontvangen",
  "Contact details updated": "Houd je adressen en contactgegevens up-to-date.",
  "Contact us": "Neem contact met ons op",
  "Continue to billing": "Doorgaan naar facturering",
  "Continue to payment": "Doorgaan naar betalen",
  "Continue to shipping": "Doorgaan naar verzending",
  "Cookies Policy": "Cookies beleid",
  "Create an account": "Maak een account",
  "Customer Reviews": "Klantenbeoordelingen",
  "Customer service": "Klantenservice",
  Date: "Datum",
  "Default Billing Address": "Standaard factuuradres",
  "Default Shipping Address": "Standaard verzendadres",
  Delete: "Verwijder",
  Departments: "Afdelingen",
  Description: "Beschrijving",
  "Details and status orders":
    "Bekijk de details en status van je bestellingen in de online shop. Je kunt je bestelling ook annuleren of een retourzending aanvragen.",
  Discount: "Korting",
  Done: "Gereed",
  Download: "Downloaden",
  "Download all": "Alles downloaden",
  Edit: "Bewerk",
  "Email address": "E-mailadres",
  Empty: " Het lijkt erop dat je nog geen items aan de winkelwagen hebt toegevoegd. Begin met winkelen om hem aan te vullen.",
  "Empty bag": "Winkelwagen legen",
  "Enjoy your free account": "Geniet van de voordelen van je gratis account!",
  "Enter promo code": "Kortingscode invoeren",
  Feedback: "Uw feedback is belangrijk voor ons. Laat ons weten wat we kunnen verbeteren.",
  "Feel free to edit": "Wijzig hieronder je gegevens zodat je account altijd up-to-date is ",
  Filters: "Filters",
  "Find out more": "Meer informatie",
  "First Name": "Voornaam",
  "Forgot Password": "Als je je wachtwoord niet meer weet, kun je het opnieuw instellen.",
  "Forgot Password Modal Email": "E-mail waarmee u zich aanmeldt:",
  forgotPasswordConfirmation:
    "Bedankt! Als er een account is geregistreerd met het {0} e-mailadres, vindt u een bericht in uw Postvak IN met een link om het wachtwoord te resetten.",
  "Forgotten password?": "Wachtwoord vergeten?",
  "Go back": "Ga terug",
  "Go back shopping": "Doorgaan met winkelen",
  "Go back to shop": "Ga terug naar de shop",
  "Go to checkout": "Naar de kassa",
  "Go to cart": "Naar winkelwagen",
  Guarantee: "Garantie",
  Help: "Help",
  "Help & FAQs": "Help & FAQs",
  hide: "verbergen",
  Home: "Home",
  "I agree to": "Ik ga akkoord met",
  "I confirm subscription": "Ik bevestig de inschrijving",
  "I want to create an account": "Ik wil een account aanmaken",
  "Info after order":
    "Je kunt inloggen op je account met het eerder opgegeven e-mailadres en wachtwoord. Op je account kun je je profielgegevens bewerken, je transactiegeschiedenis bekijken en je abonnement op de nieuwsbrief wijzigen.",
  Instruction1: "Zorg goed voor me",
  Instruction2: "Alleen hier voor de onderhoudsinstructies?",
  Instruction3: "Ja, dat dachten we al",
  "It was not possible to request a new password, please check the entered email address.":
    "Het was niet mogelijk om een nieuw wachtwoord aan te vragen. Controleer het ingevoerde e-mailadres.",
  Item: "Artikel",
  Items: "Artikelen",
  Kidswear: "Kindermode",
  "Last Name": "Achternaam",
  "Let’s start now – we’ll help you": "Laten we nu beginnen - we zullen je helpen.",
  "Log into your account": "Inloggen op je account",
  Login: "Inloggen",
  "login in to your account": "Inloggen op je account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.":
    "Het lijkt erop dat je nog niets aan de winkelwagen hebt toegevoegd. Begin met winkelen om hem aan te vullen.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Bestelling plaatsen",
  "Manage addresses": "Beheer de gewenste adressen (werkadres, thuisadres...) Zo hoef je het adres niet bij elke bestelling handmatig in te voeren.",
  "Manage billing addresses":
    "Beheer de gewenste factuuradressen (werkadres, thuisadres...) Zo hoef je het factuuradres niet bij elke bestelling handmatig in te voeren.",
  "Manage shipping addresses":
    "Beheer de gewenste verzendadressen (werkadres, thuisadres...) Zo hoef je niet bij elke bestelling handmatig het verzendadres in te voeren.",
  "Match it with": "Combineer het met",
  "Men fashion": "Herenmode",
  Menu: "Menu",
  "My billing and shipping address are the same": "Mijn factuuradres en verzendadres zijn hetzelfde",
  "My Cart": "Mijn winkelwagen",
  "No account": "Heb je nog geen account?",
  or: "of",
  "or fill the details below": "of vul de onderstaande gegevens in",
  "Order ID": "Bestel ID",
  "Order information": "Bestelinformatie",
  "Order No.": "Bestelnr.",
  "Order summary": "Besteloverzicht ",
  "Other products you might like": "Andere producten die je misschien leuk vindt",
  "Out of stock": "Niet op voorraad",
  Password: "Wachtwoord",
  "Password Changed": " Wachtwoord succesvol gewijzigd. U kunt nu teruggaan naar de startpagina en u aanmelden.",
  "Pay for order": "Bestelling betalen",
  Payment: "Betaling",
  "Payment & Delivery": "Betaling & Levering",
  "Payment Method": "Betaalmethode",
  "Payment Methods": "Betaalmethodes",
  "Personal details": "Persoonlijke gegevens",
  "Please type your current password to change your email address.": "Typ je huidige wachtwoord om je e-mailadres te wijzigen.",
  Price: "RRP Prijs",
  Privacy: "Privacy",
  "Privacy Policy": "Privacybeleid",
  Product: "Product",
  "Product suggestions": "Productsuggesties",
  Products: "Producten",
  "Products found": "Producten gevonden",
  "Purchase terms": "Aankoopvoorwaarden",
  "Quality in the details": "Kwaliteit in de details",
  Quantity: "Aantal",
  "Read all reviews": "Alle beoordelingen lezen",
  "Read and understand": "Ik heb het gelezen en begrijp de",
  "Read reviews": "Beoordelingen lezen",
  Register: "Registeren",
  "Register today": "Registreer vandaag",
  Remove: "Verwijder",
  "Remove Address": "Verwijder adres",
  "Remove from Wishlist": "Verwijderen uit verlanglijst",
  "Repeat Password": "Herhaal wachtwoord",
  "Reset Password": "Wachtwoord opnieuw instellen",
  "Review my order": "Mijn bestelling bekijken",
  "Same as shipping address": "Zelfde als verzendadres",
  "Save changes": "Wijzigingen opslaan",
  "Save for later": "Opslaan voor later",
  "Save Password": "Wachtwoord opslaan",
  Search: "Zoeken",
  "Search for items": "Artikelen zoeken",
  "Search results": "Zoekresultaten",
  "Sections that interest you": "Onderdelen die je interesseren",
  "See all results": "Bekijk alle resultaten",
  "See more": "Bekijk meer",
  "Select payment method": "Selecteer betaalmethode",
  "Select shipping method": "Selecteer verzendmethode",
  "Send my feedback": "Stuur mijn feedback",
  "Set up newsletter":
    "Stel je nieuwsbrief in en we sturen je elke week informatie over nieuwe producten en trends uit de door jou geselecteerde onderdelen.",
  "Share your look": "Deel je look",
  Shipping: "Verzending",
  "Shipping address": "Verzendadres",
  "Shipping details": "Verzendgegevens",
  "Shipping method": "Verzendmethode",
  Show: "Toon",
  "show more": "Meer tonen",
  "Show on page": "Weergeven op pagina",
  "Sign in": "Inloggen",
  "Size guide": "Maattabel",
  "Sign Up for Newsletter": "Aanmelden voor de nieuwsbrief",
  "Sort by": "Sorteren op",
  "Sort: Default": "Standaard",
  "Sort: Name A-Z": "Naam A-Z",
  "Sort: Name Z-A": "Naam Z-A",
  "Sort: SKU A-Z": "SKU A-Z",
  "Sort: SKU Z-A": "SKU Z-A",
  "Sort: Price from high to low": "Prijs van hoog naar laag",
  "Sort: Price from low to high": "Prijs van laag naar hoog",
  "Start shopping": "Begin met winkelen",
  Status: "Status",
  Subscribe: "Inschrijven",
  "Subscribe to newsletter": "Inschrijven voor nieuwsbrief",
  subscribeToNewsletterModalContent:
    "Als u zich aanmeldt voor de nieuwsbrief, ontvangt u speciale aanbiedingen en berichten van Shots via e-mail. Wij zullen uw e-mail op geen enkel moment verkopen of verspreiden aan derden. Zie onze {0}.",
  Subtotal: "Subtotaal",
  "Subtotal price": "Subtotale prijs",
  "Successful placed order":
    "Je hebt met succes de bestelling geplaatst. Je kunt de status van je bestelling volgen met behulp van onze leveringsstatusfunctie. Je ontvangt een e-mail ter bevestiging van je bestelling met de details van je bestelling en een link om de voortgang te volgen.",
  "Terms and conditions": "Algemene voorwaarden",
  "Thank you": "Bedankt",
  "Thank You Inbox":
    "Als het bericht niet aankomt in je inbox, probeer dan een ander e-mailadres dat je mogelijk hebt gebruikt om je te registreren.",
  Total: "Totaal",
  "Order Total": "Totaal bestelling",
  "Total items": "Totaal aantal items",
  "Total price": "Totale prijs",
  "Update password": "Wachtwoord bijwerken",
  "Update personal data": "Persoonlijke gegevens bijwerken",
  "Use your personal data":
    "Bij SHOTS hechten we veel belang aan privacykwesties en zetten we ons in om de persoonlijke gegevens van onze gebruikers te beschermen. Kom meer te weten over hoe wij uw persoonlijke gegevens verzorgen en gebruiken in de ",
  "User Account": "Gebruikersaccount",
  View: "Bekijk",
  "View details": "Details bekijken",
  "Vue Storefront Next": "Volgende winkelvenster",
  "Who we are": "Wie zijn wij",
  Wishlist: "Verlanglijst",
  "Women fashion": "Damesmode",
  "You added {product} to your shopping cart.": "U hebt {product} toegevoegd aan uw winkelwagen.",
  "You are not authorized, please log in": "U bent niet gemachtigd, log in aub.",
  "You can unsubscribe at any time": "U kunt zich op elk moment uitschrijven",
  "You currently have no orders": "U hebt momenteel geen bestellingen",
  "You haven’t searched for items yet": "Je hebt nog niet naar artikelen gezocht.",
  "Your wishlist is empty": "Je verlanglijst is leeg",
  "Your current email address is": "Je huidige e-mailadres is",
  "Your email": "Uw e-mail",
  "The email field must be a valid email": "Het e-mailveld moet een geldig e-mailadres zijn",
  "You have submitted no reviews": "U heeft geen beoordelingen ingediend",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":
    "Het inloggen bij het account is onjuist. Probeer het opnieuw of stel uw wachtwoord opnieuw in.",
  "A customer with the same email address already exists in an associated website.":
    "Een klant met hetzelfde e-mailadres bestaat al op een gerelateerde website.",
  "Invalid email": "Ongeldige e-mail",
  "SUMMER COLLECTION {year}": "ZOMER COLLECTIE {jaar}",
  "Colorful summer dresses are already in store": "Kleurrijke zomerjurkjes liggen al in de winkel",
  "Learn more": "Meer informatie",
  Dresses: "Jurken",
  "Cocktail & Party": "Cocktail & Feest",
  "Linen Dresses": "Linnen Jurken",
  "T-Shirts": "T-Shirts",
  "The office life": "Het kantoorleven",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.":
    "Vind prachtige cocktailjurken en feestjurken voor dames. Val op met kanten en metallic cocktailjurken van al je favoriete merken.",
  "Shop now": "Nu winkelen",
  "The Office Life": "Het kantoorleven",
  "Summer Sandals": "Zomerse sandalen",
  "Eco Sandals": "Eco sandalen",
  "Subscribe to Newsletters": "Abonneren op nieuwsbrieven",
  "Be aware of upcoming sales and events. Receive gifts and special offers!":
    "Blijf op de hoogte van aanstaande verkopen en evenementen. Geschenken en speciale aanbiedingen ontvangen",
  "Fashion to take away": "Mode om mee te nemen",
  "Download our application to your mobile": "Download onze applicatie naar je mobiel",
  "Share Your Look": "Deel je look",
  "My Account": "Mijn account",
  "My profile": "Mijn profiel",
  "Personal Details": "Persoonlijke gegevens",
  "Addresses details": "Adresgegevens",
  "My newsletter": "Mijn nieuwsbrief",
  "Log out": "Uitloggen",
  "My reviews": "Mijn beoordelingen",
  "Order history": "Bestelgeschiedenis",
  "Order details": "Bestelinformatie",
  "My wishlist": "Mijn verlanglijst",
  "Password change": "Wachtwoord wijzigen",
  "Personal data": "Persoonlijke gegevens",
  "Your e-mail": "Uw e-mail",
  "Current Password": "Huidig wachtwoord",
  "You are not authorized, please log in.": "U bent niet gemachtigd, log in.",
  "Go To Product": "Ga naar product",
  "Change to list view": "Verander naar lijstweergave",
  "Change to grid view": "Verander naar rasterweergave",
  Returns: "Retouren",
  "My orders": "Mijn bestellingen",
  "Add the address": "Adres toevoegen",
  "Set as default shipping": "Instellen als standaardverzending",
  "Set as default billing": "Instellen als standaardfacturering",
  "House/Apartment number": "Huis-/appartementsnummer",
  "Street Name": "Straatnaam",
  City: "Stad",
  "State/Province": "Staat/provincie",
  "Zip-code": "Postcode",
  Country: "Land",
  "Phone number": "Telefoonnummer",
  "Please select a country first": "Selecteer eerst een land",
  "This field is required": "Dit veld is verplicht",
  "The field should have at least 2 characters": "Het veld moet minstens 2 tekens bevatten",
  "The field should have at least 4 characters": "Het veld moet minstens 4 tekens bevatten",
  "The field should have at least 8 characters": "Het veld moet minstens 8 tekens bevatten",
  "New Password": "Nieuw wachtwoord",
  "New Products": "Nieuwe producten",
  "There are no shipping methods available for this country. We are sorry, please try with different country.":
    " Er zijn geen verzendmethoden beschikbaar voor dit land. Het spijt ons, probeer het met een ander land.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.":
    "Er is een fout opgetreden bij het ophalen van de verzendmethoden. Het spijt ons, probeer het alstublieft met andere verzendgegevens.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.":
    " Er is een fout opgetreden bij het selecteren van deze verzendmethode. Het spijt ons, probeer het alstublieft met een andere verzendmethode.",
  "We can't find products matching the selection.": " We kunnen geen producten vinden die overeenkomen met de selectie.",
  "Page not found": "Pagina niet gevonden",
  "Back to Home page": "Terug naar de homepagina",
  "An error occurred": "An error occurred",
  AllProductsFromCategory: " Alle {categoryName}",
  "Show more": "Meer tonen",
  "Show less": "Minder tonen",
  Yes: "Ja",
  No: "Nee",
  "Apply filters": "Filters toepassen",
  "The coupon code isn't valid. Verify the code and try again.": "De couponcode is niet geldig. Controleer de code en probeer het opnieuw.",
  From: "Van",
  To: "Voor",
  "Your customization": "Jouw aanpassing",
  "Passwords don't match": "Wachtwoorden komen niet overeen",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":
    " Het wachtwoord moet ten minste 8 tekens lang zijn en ten minste het volgende bevatten: 1 hoofdletter of kleine letter, 1 cijfer of een speciaal teken (bijv. , . _ & ? etc)",
  "Show all products": "Toon alle producten",
  "Select previously saved address": "Selecteer een eerder opgeslagen adres",
  "Enter different address": "Ander adres invoeren",
  "You must confirm your account. Please check your email for the confirmation link.":
    "Je moet je account bevestigen. Controleer uw e-mail voor de bevestigingslink.",
  "Change Store": "Verander winkel",
  "Choose Currency": "Valuta kiezen",
  "Add a review": "Een recensie toevoegen",
  "Add to cart": "In winkelwagen",
  Title: "Titel",
  Name: "Naam",
  Review: "Beoordeling",
  "Add review": "Beoordeling toevoegen",
  "Are you sure you would like to remove this item from the shopping cart?":
    " Weet u zeker dat u dit artikel uit het winkelwagentje wilt verwijderen?",
  "Your cart is empty": "De winkelwagen is leeg",
  "Are you sure?": "Weet u het zeker?",
  "{0} has been successfully removed from your cart": "{0} is succesvol verwijderd uit uw winkelwagen",
  Amount: "Bedrag",
  "Thank you for your order!": "Bedankt voor uw bestelling!",
  "Your Purchase": "Uw aankoop",
  "Primary contacts for any questions": "Primaire contactpersonen voor vragen",
  "Your Account": "Uw account",
  "What can we improve": "Wat kunnen we verbeteren",
  "Payment date": "Betaaldatum",
  "The user password was changed successfully updated!": "Het gebruikerswachtwoord is succesvol gewijzigd!",
  "The user account data was successfully updated!": "De gebruikersaccountgegevens zijn succesvol bijgewerkt!",
  "You submitted your review for moderation.": "Je hebt je beoordeling ingediend voor moderatie.",
  "Starting at": "Vanaf",
  "$ dollar": "$ dollar",
  "€ euro": "€ euro",
  "America, english ($ dollar)": "America, english ($ dollar)",
  "Europe, Nederland (€ euro)": "Europe, Netherland (€ euro)",
  English: "Engels",
  French: "Frans",
  German: "Duits",
  Nederland: "Nederlands",
  Spain: "Spaans",
  "EU Website": "Shots Europe",
  "EU Website, English": "Shots Europe, English",
  "EU Website, French": "Shots Europe, France",
  "EU Website, German": "Shots Europe, Germany",
  "EU Website, Nederland": "Shots Europe, Netherlands",
  "EU Website, Spain": "Shots Europe, Spain",
  EnglishUS: "America, English",
  SpanishUS: "America, Spanish",
  "US Website": "Shots America",
  "US Website, English US": "Shots America, English",
  "US Website, Spanish US": "Shots America, Spain",
  "Stay on Shots America": "Stay on Shots America",
  "My wishlist": "Mijn verlanglijst",
  "Existing customer account": "Inloggen als bestaande klant",
  "Login as customer": "Inloggen als klant",
  "Become a Shots B2B partner": "Word een Shots B2B klant",
  "Go to registration form": "Ga naar het registratieformulier",
  "Request password reset": "Nieuw wachtwoord aanvragen",
  "Please use one uppercase and one number in your password.": "Gebruik één hoofdletter en één cijfer in je wachtwoord.",
  "Logged In": "Ingelogd",
  "Password reset email is sent": "E-mail voor het resetten van het wachtwoord is verzonden",
  "Send request": "Aanvraag verzenden",
  "More than 10.000 products on stock": "Meer dan 10.000 producten op voorraad",
  "Europe's nr. 1 manufacturer, distributor and designer of intimacy products":
    "Europa's nr. 1 fabrikant, distributeur en ontwerper van intimiteitsproducten",
  "Fast delivery all over the globe": "Snelle levering over de hele wereld",
  "Dealer registration form": "Registratieformulier voor klanten",
  "Company details": "Bedrijfsgegevens",
  Website: "Website",
  "Main address details": "Adresgegevens",
  Gender: "Geslacht",
  Male: "Man",
  Female: "Vrouw",
  "Non-binary": "Non-binair",
  "Account preferences": "Accountvoorkeuren",
  "Password does not match": "Wachtwoord komt niet overeen",
  "Confirm Password": "Wachtwoord bevestigen",
  "Send me personalized offers and other information from Shots in the future.":
    "Stuur me in de toekomst gepersonaliseerde aanbiedingen en andere informatie van Shots.",
  "Apply for a dealer account": "Een klantenaccount aanvragen",
  "Company name": "Bedrijfsnaam",
  "Chamber of Commerce nr.": "Kamer van Koophandel nr.",
  "VAT registration nr.": " BTW-nummer.",
  "Company Type": "Type bedrijf",
  State: "Staat",
  "Street name": "Straatnaam",
  Number: "Nummer",
  "Zip code": "Postcode",
  "First name": "Voornaam",
  "Last name": "Achternaam",
  "Article number": "Artikelnummer",
  "EAN code": "EAN-code",
  "Quick Links": "Snelle links",
  "Product description": "Productbeschrijving",
  "Package content": "Inhoud verpakking",
  Specifications: "Specificaties",
  Support: "Ondersteuning",
  "Order this product": "Dit product bestellen",
  "Recommended Retail Price:": " Aanbevolen verkoopprijs:",
  "More then 10.000 products on stock": "Meer dan 10.000 producten op voorraad",
  "Read more": "Lees meer",
  "All products": "All products",
  "Choose a language": "Kies een taal",
  "Your current orders": "Uw huidige bestellingen",
  Orders: "Bestellingen",
  Backorders: "Nabestellingen",
  "Product feeds": "Product feeds",
  Imagebank: "Imagebank",
  "Account details": "Accountgegevens",
  "Addresses book": "Adresboek",
  "Return Products": " Producten retourneren",
  "Logout from my account": "Afmelden bij mijn account",
  "Return products": "Producten retourneren",
  "Paid orders": "Betaalde bestellingen",
  "Unpaid orders": "Onbetaalde bestellingen",
  "Issue a payment for my unpaid orders": "Mijn onbetaalde bestellingen betalen",
  Welcome: "Welkom",
  "My account": "Mijn account",
  Inspiration: "Inspiratie",
  Sale: "Verkoop",
  "About us": "Over ons",
  "Customer service": "Klantenservice",
  Contact: "Contact",
  "Total in cart": "Totaal in winkelwagen",
  "Load More products": "Meer producten laden",
  "Amount of products": "Hoeveelheid producten",
  Sorting: "Sorteren",
  "Sort by newest": "Sorteren op nieuwste",
  "In Stock": "Op voorraad",
  Discover: "Ontdek",
  "our top 10": "onze top 10",
  "Choose a": "Kies een",
  Category: "Catogorie",
  "per page": "per pagina",
  "Your price": "Uw prijs",
  "Your margin": "Uw marge",
  "Wholesale price": "Groothandelsprijs",
  "Retail price": "Advies winkelprijs",
  "Add this product to wishlist": "Dit product toevoegen aan verlanglijst",
  "Remove this product from wishlist": "Verwijder dit product uit verlanglijst",
  "Sign up": "Aanmelden",
  "Company Name": "Bedrijfsnaam",
  "Your name": "Uw naam",
  "Company type": "Bedrijfstype",
  "Email address": "E-mailadres",
  "Send this form": "Stuur dit formulier",
  "You've added a quantity greater than what's currently in stock. The available amount will be shipped right away, and the remaining items will be shipped as soon as possible.":
    " Je hebt een hoeveelheid toegevoegd die groter is dan wat er op dit moment op voorraad is. Het beschikbare aantal wordt meteen verzonden en de resterende artikelen worden zo snel mogelijk verzonden.",
  "in stock": "op voorraad",
  "in production": "in productie",
  "Est. arrival": "Verwacht:",
  "See all": " Bekijk alles",
  "search results": "zoekresultaten",
  "No products were found that match your selection.": "Er zijn geen producten gevonden die aan je selectie voldoen.",
  "No products were found ": "Er zijn geen producten gevonden",
  "that match your selection.": "die aan je selectie voldoen.",
  "Total results found": "Totaal gevonden resultaten",
  "Search results for": "Zoekresultaten voor",
  "Browse by logo": "Zoeken op logo",
  "Browse by name": "Zoek op naam",
  Enabled: "Ingeschakeld",
  "Only in Stock": "Alleen op voorraad",
  Update: "Update",
  "Download CSV": "CSV downloaden",
  "Download XML": "XML downloaden",
  "Please be aware that the feeds are updated once every 5 minutes, so your download buttons may only work after that time has passed.":
    " Houd er rekening mee dat de feeds elke 5 minuten worden bijgewerkt, dus het kan zijn dat de downloadknoppen pas na die tijd werken.",
  "Show Menu": "Menu weergeven",
  "My Account Menu": "Mijn account menu",
  Logout: "Uitloggen",
  "Select Categories": "Categorieën selecteren",
  "File Prefix": "Bestandsvoorvoegsel",
  "Press here to copy the link": "Klik hier om de link te kopiëren",
  "There is no enough quantity in stock for some items, would you rather?": " Er is niet genoeg voorraad voor sommige artikelen, wil je liever?",
  "Allow Backorder for this order only": "Backorder alleen voor deze bestelling toestaan",
  "Order only items that are available right away": "Bestel alleen artikelen die direct beschikbaar zijn",
  "Apply discount": "Korting toepassen",
  "Remove discount": "Verwijder korting",
  "You are not allowed to do this": "Dit mag u niet doen",
  "Successfully applied": "Succesvol toegepast",
  "Successfully removed": "Succesvol verwijderd",
  "Allow Backorders": "Backorders toestaan",
  "All items from this order are added to the cart": "Alle artikelen van deze bestelling worden toegevoegd aan de winkelwagen",
  "Quick Order": "Snel bestellen",
  "Upload CSV file and add products in bulk": "CSV-bestand uploaden en producten in bulk toevoegen",
  "Choose a file": "Kies een bestand",
  "Download CSV Example": "CSV voorbeeld downloaden",
  "Upload CSV for quick order": "CSV uploaden om snel te bestellen",
  "Preferred Store View": "Voorkeur Winkeloverzicht",
  VAT: "BTW",
  "Discontinued Soon": "Binnenkort beëindigd",
  Outlet: "Uitverkoop",
  Promotional: "Promotie",
  "Earlier Ordered": "Eerder besteld",
  "Never Ordered": "Nooit besteld",
  "Top Sellers": "Top sellers",
  "Coming Soon": "Binnenkort beschikbaar",
  "Only products in last": "De meest recente producten",
  "days. Zero for no limit.": "dagen. Nul voor geen limiet.",
  "batteries quantity": "hoeveelheid batterijen",
  "batteries included": "batterijen meegeleverd",
  "battery lifetime": "levensduur van de batteri",
  "time to recharge": "tijd om op te laden",
  "phthalate free": "ftalaatvrij",
  "noise level": "geluidsniveau",
  "textile composition": "textielsamenstelling",
  "product volume": "productvolume",
  "inner diameter": "binnendiameter",
  "lithium inside": "lithium binnenin",
  yes: "ja",
  no: "nee",
  "Feed for Store View": "Feed for Store View",
  "hold ctrl for multiselect": "hold ctrl for multiselect",
  "Download cart in CSV": "Download cart in CSV",
  "Remove All Items": "Remove All Items",
  "Extensive Inventory": "Extensive Inventory",
  "Swift Global Delivery": "Swift Global Delivery",
  "European excellence in products and development": "European excellence in products and development",
  products: "products",
  "Let’s find what you are looking for": "Laten we vinden waar u naar op zoek bent",
  Brands: "Merken",
  "The quantity has not been added as a whole number.": "De hoeveelheid is niet toegevoegd als een rond getal.",
  "We apologize, but this product isn't in our database. Please contact your sales agent for assistance.":
    "Onze excuses, maar dit product staat niet in onze database. Neem contact op met uw verkoopagent voor hulp..",
  "Select Brands": "Select Brands",
  "Please select at least one category": "Gelieve minstens één categorie te selecteren.",
  "Select All": "Alles selecteren",
  "Our website uses cookies, but don't worry, they're the digital kind!":
    "Onze website maakt gebruik van cookies, maar maak jegeen zorgen, het zijn digitale cookies!",
  "Just a springle of tech magic to make your browsing a happy moment.":
    "Gewoon een beetje technische magie om van het surfen een gelukkig moment te maken.",
  "Not feeling it? No need for your green light here.": "Geen zin? Je hoeft hier geen groen licht te geven.",
  "The standard settings are our go-to whenever you drop by.": "De standaardinstellingen zijn onze go-to wanneer je ook langskomt.",
  Allow: "Toestaan",
  Transport: "Transport",
  "In backorder": "In nabestelling",
  "Product added to your shopping cart": "Product toegevoegd aan uw winkelwagen",
  "View shopping cart": "Bekijk winkelwagen",
  "Proceed to payment": "Ga door naar betaling",
  "Continue shopping": "Doorgaan met winkelen",
  "In backorder": "In nabestelling",
  "The following backorder products will be removed from your cart:":
    "TDe volgende producten die momenteel in backorder zijn, worden uit uw winkelwagen verwijderd:",
  "Refresh Cart": "Vernieuw Winkelwagen",
  placeholders: {
    email: "Emailaddress",
  },
  "Allow backorder": "Achteraf bestellen toestaan",
  "Only available": "Alleen beschikbaar",
  "Allow backorder": "Achteraf bestellen toestaan",
  "Only available": "Alleen beschikbaar",
  placeholders: {
    email: "Emailaddress",
  },
  "Explanation Feed": "Uitleg: hoe de feed te gebruiken",
  "Ready for a seamless product discovery with our intuitive feed tool. Tailor your feed to your interests, effortlessly staying informed and con-nected. Here you’ll find an explanation how to implement the feed. Let’s get started!":
    "Klik op de onderstaande downloadlink om het uitleg-PDF-bestand over het gebruik van de feed te downloaden.",
  "Article Number": "Artikelnummer",
  "Article Name": "Artikelnaam",
  Quantity: "Aantal",
  "Expected in": "Verwacht binnen",
  Unknown: "Onbekend",
  "Unpaid Invoices": "Onbetaalde facturen",
  "Make payment": "Betaling doen",
  "Paid Invoices": "Betaalde facturen",
  Invoices: "Facturen",
  Download: "Downloaden",
  "Order date": "Besteldatum",
  OrderNumberInvoices: "Ordernummer",
  Invoice: "Factuur",
  "Shipping info": "Verzending",
  "Invoice amount": "Factuurbedrag",
  Outstanding: "Uitstaand",
  "Product not available": "Product niet beschikbaar",
  "Max char length exceeded": "Maximale tekenlengte overschreden",
  "Add wishlist items to cart": "Voeg verlanglijst items toe aan winkelwagen",
  "Case count:": "Aantal gevallen:",
  "Your discount": "Uw korting",
  "My Credit Invoices": "Mijn creditfacturen",
  "Send with next new order": "Verzenden met de volgende nieuwe bestelling",
  "Complete delivery": "Volledige levering",
  "Send with next new order": "Verzenden met de volgende nieuwe bestelling",
  "Complete delivery": "Volledige levering",
  "Case count:": "Aantal in doos:",
  "Your discount": "Uw korting",
  "Credit Invoices": "Creditfacturen",
  "Please use valid zip code": "Gebruik alstublieft een geldige postcode",
  "Please use +11234567890 for phone number format": "Gebruik alstublieft +11234567890 voor het telefoonnummerformaat",
};
